import React from 'react';

//Components
import Intro from '../../components/intro';
import Blocks from '../../components/blocks';
import SectionText from '../../components/section-text';
import SliderProcesses from '../../components/slider-processes';

const SectorsPageTemplate = ({ data, cmsPreview, lang }) => {
  return (
    <>
      <Intro data={data.intro} />

      <SectionText data={data.information} />

      <Blocks data={data.sectors} alt />

      <SliderProcesses lang={lang} cmsPreview={cmsPreview} />
    </>
  );
};

export default SectorsPageTemplate;
