import { useState, useRef, useEffect } from 'react';

const useObserver = ({ init, root = null, rootMargin, threshold = 0 }) => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    if (init) {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new window.IntersectionObserver(
        ([entry], observer) => {
          updateEntry(entry);
          if (entry.isIntersecting) {
            observer.disconnect();
          }
        },
        {
          root,
          rootMargin,
          threshold,
        }
      );

      const { current: currentObserver } = observer;

      if (node) {
        currentObserver.observe(node);
      }

      return () => currentObserver.disconnect();
    }
  }, [init, node, root, rootMargin, threshold]);

  if (!init) return [null, false];

  return [node, setNode, entry.isIntersecting];
};

export default useObserver;
